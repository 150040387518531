import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDefineUserRole } from "../../../../../hooks/useDefineUserRole";
import { IAppState } from "../../../../../store/rootDuck";
import { useGetSettings } from "../../../holding/hooks/useGetSettings";
import { IBid } from "../../../../../interfaces/bids";

export const useGetPercent = () => {
  const { fetchSettings, settings, loading: loadingSettings } = useGetSettings();

  const { me } = useSelector((store: IAppState) => ({
    me: store.auth.user,
  }));

  const isBuyer = useDefineUserRole(me, "ROLE_BUYER");
  const isVendor = useDefineUserRole(me, "ROLE_VENDOR");

  const [vendorFee, setVendorFee] = useState(0);
  const [buyerFee, setBuyerFee] = useState(0);

  useEffect(() => {
    setVendorFee(settings?.vendor_fee || 0);
    setBuyerFee(settings?.buyer_fee || 0);
  }, [settings]);

  const getPricePercent = useCallback(
    (price: number, bid: IBid) => {
      if (!isBuyer && !isVendor) {
        if (bid.type === "purchase") {
          if (!bid.payment_term && !bid.prepayment_amount) return price;
          const prepayment = bid.prepayment_amount === 100 ? 1 : bid.payment_term;
          return Math.floor(price / (1 + ((vendorFee / 100) * prepayment) / 30));
        }
        if (bid.type === "sale") {
          return Math.ceil(price + (price * buyerFee) / 100);
        }
        return price;
      }
      if (isVendor) {
        if (!bid.payment_term && !bid.prepayment_amount) return price;
        const prepayment = bid.prepayment_amount === 100 ? 1 : bid.prepayment_amount;
        return Math.floor(price / (1 + ((vendorFee / 100) * prepayment) / 30));
      }
      if (isBuyer) {
        return Math.ceil(price + (price * buyerFee) / 100);
      }
      return price;
    },
    [vendorFee, buyerFee, isBuyer, isVendor]
  );

  return { fetchSettings, loadingSettings, getPricePercent };
};
