import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { getResponseMessage } from "../../../../utils";
import { ITrialToRequest } from "../../../../interfaces/trial";
import { editTrial } from "../../../../crud/trial.crud";
import { useIntl } from "react-intl";

export const useEditSettings = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingEdit, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const intl = useIntl();

  const fetchEdit = useCallback(async (data: ITrialToRequest) => {
    setLoading(true);
    editTrial(data)
      .then(() => {
        enqueueSnackbar(intl.formatMessage({ id: "CHANGES.SAVED" }), { variant: "success" });
        setSuccess(true);
      })
      .catch(e => {
        enqueueSnackbar(`${intl.formatMessage({ id: "ERROR" })}: ${getResponseMessage(e)}`, { variant: "error" });
      })
      .finally(() => {
        setLoading(false);
        setSuccess(false);
      });
  }, []);

  return { fetchEdit, loadingEdit, success };
};
