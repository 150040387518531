import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { getResponseMessage } from "../../../../utils";
import { ITrial } from "../../../../interfaces/trial";
import { getTrial } from "../../../../crud/trial.crud";
import { useIntl } from "react-intl";

export const useGetSettings = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState<ITrial | null>(null);
  const intl = useIntl();

  const fetchSettings = useCallback(async () => {
    setLoading(true);
    getTrial()
      .then(res => {
        setSettings(res.data.data);
      })
      .catch(e => {
        enqueueSnackbar(`${intl.formatMessage({ id: "ERROR" })}: ${getResponseMessage(e)}`, { variant: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { fetchSettings, loading, settings };
};
