import React, { useLayoutEffect } from "react";
import { useIntl } from "react-intl";
import { Paper, TextField } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useFormik } from "formik";

import useStyles from "../styles";
import { actions as authActions } from "../../../store/ducks/auth.duck";
import { IAppState } from "../../../store/rootDuck";
import { LayoutSubheader } from "../../../../_metronic/layout/LayoutContext";
import ButtonWithLoader from "../../../components/ui/Buttons/ButtonWithLoader";
import NumberFormatTrial from "../../../components/NumberFormatCustom/NumberFormatTrial";
import { useGetSettings } from "./hooks/useGetSettings";
import { useEditSettings } from "./hooks/useEditSettings";
import NumberFormatFloat from "../../../components/NumberFormatCustom/NumberFormatFloat";
import { useDispatch, useSelector } from "react-redux";
import { useDefineUserRole } from "../../../hooks/useDefineUserRole";

const HoldingPage: React.FC = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { fetchSettings, loading, settings } = useGetSettings();
  const { loadingEdit, success, fetchEdit } = useEditSettings();

  const { me, loadingMe } = useSelector((store: IAppState) => ({
    me: store.auth.user,
    loadingMe: store.auth.loading,
  }));

  const isAdmin = useDefineUserRole(me, "ROLE_ADMIN");

  useLayoutEffect(() => {
    dispatch(authActions.fetchRequest());
    fetchSettings();
  }, []);

  useLayoutEffect(() => {
    success && fetchSettings();
  }, [success]);

  const { values, handleSubmit, handleChange } = useFormik({
    enableReinitialize: true,
    initialValues: {
      vendorFee: settings?.vendor_fee || 0,
      buyerFee: settings?.buyer_fee || 0,
      holdingEmail: settings?.holding_email || "",
      holdingPhone: settings?.holding_phone || "",
    },
    onSubmit: values => {
      fetchEdit({
        ...values,
        vendorFee: values.vendorFee || 0,
        buyerFee: values.buyerFee || 0,
      });
    },
  });

  if (!isAdmin) return <></>;

  return (
    <Paper className={classes.paperWithForm}>
      <LayoutSubheader title={intl.formatMessage({ id: "HOLDING" })} />
      <div className={classes.form}>
        <div className={classes.textFieldContainer}>
          {loading || loadingMe ? (
            <Skeleton width="100%" height={70} animation="wave" />
          ) : (
            <TextField
              type="text"
              label={intl.formatMessage({ id: "HOLDING.VENDOR.FEE" })}
              margin="normal"
              className={classes.textField}
              name="vendorFee"
              value={values.vendorFee}
              variant="outlined"
              onChange={handleChange}
              InputProps={{
                inputComponent: NumberFormatFloat as any,
              }}
              autoComplete="off"
            />
          )}
        </div>

        <div className={classes.textFieldContainer}>
          {loading || loadingMe ? (
            <Skeleton width="100%" height={70} animation="wave" />
          ) : (
            <TextField
              type="text"
              label={intl.formatMessage({ id: "HOLDING.BUYER.FEE" })}
              margin="normal"
              className={classes.textField}
              name="buyerFee"
              value={values.buyerFee}
              variant="outlined"
              onChange={handleChange}
              InputProps={{
                inputComponent: NumberFormatFloat as any,
              }}
              autoComplete="off"
            />
          )}
        </div>

        <div className={classes.textFieldContainer}>
          {loading || loadingMe ? (
            <Skeleton width="100%" height={70} animation="wave" />
          ) : (
            <TextField
              type="text"
              label={intl.formatMessage({ id: "HOLDING.EMAIL" })}
              margin="normal"
              className={classes.textField}
              name="holdingEmail"
              value={values.holdingEmail}
              variant="outlined"
              onChange={handleChange}
              autoComplete="off"
            />
          )}
        </div>

        <div className={classes.textFieldContainer}>
          {loading || loadingMe ? (
            <Skeleton width="100%" height={70} animation="wave" />
          ) : (
            <TextField
              type="text"
              label={intl.formatMessage({ id: "HOLDING.PHONE" })}
              margin="normal"
              className={classes.textField}
              name="holdingPhone"
              value={values.holdingPhone}
              variant="outlined"
              onChange={handleChange}
              InputProps={{
                inputComponent: NumberFormatTrial as any,
              }}
              autoComplete="off"
            />
          )}
        </div>

        <div className={classes.bottomButtonsContainer}>
          <div className={classes.button}>
            <ButtonWithLoader loading={loading || loadingEdit} disabled={loading || loadingEdit} onPress={handleSubmit}>
              {intl.formatMessage({ id: "ALL.BUTTONS.SAVE" })}
            </ButtonWithLoader>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default HoldingPage;
